
.insight-slide{
    --slide-margin: 20px;
    --slide-image-gap: 60px; // active, default 이미지 width 차이
    margin-right: calc(var(--slide-margin) * -1);
    transition: .3s ease-out;
    .swiper-slide-next{
        margin-left: var(--slide-image-gap) !important;
    }
    .swiper-slide-active{
        .insight-item__thumb-inner{
            box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity));
        }
    }
    ::v-deep{
        .swiper-container{
            overflow: unset;
        }
    }
    .insight-item{
        overflow: unset;
        &__inner{
            padding-right: var(--slide-margin);
        }
        &__thumb{
            width: 100%;
            transition: .3s ease-out;
            &-inner{
                transition: .2s ease-out;
            }
            &-wrap{
                overflow: unset;
            }
            &:not(.swiper-slide-active .insight-item__thumb){
                padding-right: var(--slide-image-gap);
            }
        }
        &__txt:not(.swiper-slide-active .insight-item__txt){
            max-width: calc(100% - var(--slide-image-gap));
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .insight-slide{
        --slide-margin: 40px;
        --slide-image-gap: 100px;
    }
}
@media (min-width:1024px){
    .insight-slide{
        --slide-margin: 60px;
        --slide-image-gap: 140px;
        .swiper-slide-active{
            .insight-item__thumb-inner{
                box-shadow: var(--shadow-elevation-16) rgba(var(--shadow-color), var(--shadow-opacity));
            }
        }
    }
}
@media (min-width:1200px){
}

