
.row{
    >.introduction-item{
        border-top: 1px solid rgba(255, 255, 255, .15);
        &:last-child{
            border-bottom: 1px solid rgba(255, 255, 255, .15);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row{
        >.introduction-item{
            border-top: 0;
            border-left: 1px solid rgba(255, 255, 255, .15);
            &:last-child{
                border-bottom: 0;
                border-right: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

