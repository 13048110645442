
.media-item{
    &__thumb{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s ease-out;
    }
}
::v-deep{
    .v-bottom-sheet{
        background-color: rgba(0, 0, 0, .8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        .v-btn--absolute.v-btn--top.v-btn--right{
            top: 6px;
            right: 6px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-bottom-sheet{
            .v-btn--absolute.v-btn--top.v-btn--right{
                top: 50px;
                right: 50px;
            }
        }
    }
}
@media (min-width:1024px){
    .media-item:hover{
        .media-item__thumb{
            background-color: rgba(0, 0, 0, .8);
        }
    }
}
@media (min-width:1200px){ 
}
