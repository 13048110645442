
.inline-group {
    > ul {
        margin: 0 -4px;
        > li {
            float: left;
            font-size: 1.4rem;
            line-height: 1.7;
            color: var(--txt-color-light);
            padding: 0 4px;
        }
    }

    &--lg {
        > ul {
            margin: -2px -8px;
            > li {
                padding: 2px 8px;
            }
        }
    }

    .clear {
        clear: both;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .inline-group {
        > ul {
            margin: 0 -6px;
            > li {
                font-size: 1.6rem;
                padding: 0 6px;
            }
        }

        &--lg {
            > ul {
                margin: -6px -12px;
                > li {
                    padding: 6px 12px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
