
.icon-symbol{
    &-wrap{
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--v-primary-base);
    &__inner{
        display: block;
        width: 4px;
        height: 4px;
        background-color: var(--v-primary-lighten3);
    }
    &--white{
        border-color: #fff;
        .icon-symbol__inner{
            background-color: #fff;
        }
    }
}
