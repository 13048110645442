
.swiper-pagination--around-outlined {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                position: relative;
                width: 18px;
                height: 18px;
                background-color: transparent;
                border: 0;
                opacity: 1;
                margin: 0 4px;
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    display: block;
                    border-radius: 100%;
                }
                &::before {
                    z-index: -1;
                    width: 6px;
                    height: 6px;
                    opacity: 0.4;
                    background-color: currentColor;
                }
                &::after {
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: transparent;
                    border: 1px solid currentColor;
                }
                &-active {
                    &::before {
                        opacity: 1;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--around-outlined {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    margin: 0 6px;
                    width: 20px;
                    height: 20px;
                    &::before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
