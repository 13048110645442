
// main-visual
.main-visual{
    width: 100%;
    overflow: hidden;
    position: relative;
    ::v-deep{
        .swiper-pagination{
            bottom: 120px;
        }
    }
    &__slide{
        position: relative;
        &__inner{
            width: 100%;
            height: calc(100vh - var(--header-body-height));
            display: flex;
            align-items: center;
            padding-bottom: 100px;
            box-sizing: border-box;
            ::v-deep{
                .swiper-control {
                    max-width: calc(var(--container-lg));
                }
            }
        }
        &__bg{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            background-image: url(/images/main/visual.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transform: scale(1.4);
            transform-origin: 70% center;
            transition: 7s ease-in-out;
        }
        &__title{
            font-size: 3.2rem;
            color: #fff;
            line-height: 1.3;
            word-break: keep-all;
            transform: translateY(100%);
            opacity: 0;
            visibility: hidden;
            transition: .7s ease-out;
            ::v-deep{
                >span{
                    color: rgba(255, 255, 255, .4);
                }
            }
        }
        &__ani{
            >h2:nth-child(1){
                transition-delay: .2s;
            }
            >h2:nth-child(2){
                transition-delay: .3s;
            }
            >h2:nth-child(3){
                transition-delay: .4s;
            }
        }
    }
    &__bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &__bottom-inner{
        max-width: 1920px;
        margin: 0 auto;
    }
    &__bottom-card{
        &,
        .main-visual__bottom-arrow{
            transition: .2s ease-out;
        }
        .main-visual__bottom-arrow{
            color: var(--v-grey-lighten3);
        }
    }
    .swiper-slide-active{
        .main-visual__slide__ani>h2{
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
        .main-visual__slide__bg{
            transform: scale(1);
        }
    }
}
@media (min-width: 576px) {
    .main-visual{        
        padding-bottom: 0px;
    }
}
@media (min-width: 768px) {
    .main-visual{
        &__slide{
            &__title{
                font-size: 5rem;
            }
        }        
        ::v-deep{
            .swiper-pagination{                
                left: auto;
                right: var(--container-gutter);
                transform: translate(0, -50%);
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 50vw;
                    height: 1px;
                    top: 50%;
                    right: -18px;
                    transform: translate(100%, -50%);
                    background-color: #fff;
                }
            }
        }
    }
    .main-visual__bottom-card-group>[class*="col"]{
        position: relative;
        top: 100px;
        opacity: 0;
        visibility: hidden;
        animation: fadeUp .8s ease-out forwards;
        &:nth-child(1){
            animation-delay: .8s;
        }
        &:nth-child(2){
            animation-delay: .9s;
        }
        &:nth-child(3){
            animation-delay: 1s;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual{
        &__slide{
            &__title{
                font-size: 7.2rem;
            }
        }
    }
    .v-application{
        .main-visual__bottom-card:hover{
            background-color: var(--v-primary-base) !important;
            .main-visual__bottom-arrow{
                color: #fff;
            }
        }
    }
}
@media (min-width: 1200px) {
    .main-visual{
        &__slide{
            &__inner{
                height: 100vh;
                padding-bottom: 0;
            }
        }       
        ::v-deep{
            .swiper-pagination{
                top: 50%;
            }
        }
    }
}

@keyframes fadeUp{
    0%{
        top: 100px;
        opacity: 0;
        visibility: hidden;
    }
    100%{
        top: 0;
        opacity: 1;
        visibility: visible;
    }
}
