
.story-img{
    margin-bottom: calc(var(--tit-font-size-xl) / -2 );
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-img{
        margin-bottom: -40px;
    }
}
@media (min-width:1200px){
}

