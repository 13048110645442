
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 6px;
            }
            &-x-small {
                width: 7px;
            }
            &-small {
                width: 8px;
            }
            &-default {
                width: 9px;
            }
            &-large {
                width: 10px;
            }
            &-x-large {
                width: 10px;
            }
            &-xx-large {
                width: 10px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 6px;
            }
            &-x-small {
                height: 7px;
            }
            &-small {
                height: 8px;
            }
            &-default {
                height: 9px;
            }
            &-large {
                height: 10px;
            }
            &-x-large {
                height: 10px;
            }
            &-xx-large {
                height: 10px;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--direction {
        &-left,
        &-right {
            // Size
            &.icon--size {
                &-x-large {
                    height: 12px;
                }
                &-xx-large {
                    width: 14px;
                }
            }
        }
        &-up,
        &-down {
            // Size
            &.icon--size {
                &-x-large {
                    height: 12px;
                }
                &-xx-large {
                    height: 14px;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
