
// Size
.icon--size {
    &-default {
        width: 40px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--size {
        &-default {
            width: 48px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

