
.main-section--lawyer {
    overflow: hidden;
    &__buttons,
    &__img {
        min-width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}
.swiper {
    &--left {
        &,
        .swiper-wrapper,
        .swiper-slide {
            width: 100%;
            height: 100%;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section--lawyer {
        &__buttons,
        &__img {
            min-width: unset;
            left: 0;
            transform: translateX(0);
        }
        &__contents {
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 240px;
                height: 100%;
                top: 0;
                right: 0;
                transform: translateX(100%);
                background-image: url(/images/main/lawyer-bg.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        &__left {
            max-width: calc(100% - 240px);
        }
    }
    .lawyer-info {
        overflow: hidden;
        max-height: 84px;
        flex-direction: column;
        > [class*="col"] {
            width: 50%;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-section--lawyer {
        &__info {
            max-width: calc(100% - 540px);
        }
    }
}
