
@media (min-width:576px){
}
@media (min-width:768px){
    .main-section--business{
        &__inner{
            position: relative;
        }
        &__tit{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &__contents{
            padding-top: calc(var(--tit-font-size-xl) / 2);
        }
    }
}
@media (min-width:1024px){
    .business-item{
        transition: .2s ease-out;
    }
    .v-application{
        .business-item:hover{
            background-color: var(--v-primary-base) !important;
            *{
                color: #fff !important;
            }
        }
    }
}
@media (min-width:1200px){
}
