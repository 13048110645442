
.popup-layer {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    max-width: calc(100vw - var(--container-gutter) * 2);
}
.popup-layer__body {
    overflow: hidden;
}
.popup-layer-content {
    line-height: 0;
}
.popup-layer__foot {
    padding: 4px 8px;
    background-color: #f7f7f7;
}
@media (max-width: 576px) {
    .popup-layer__body {
        width: 100% !important;
        height: auto !important;
    }
}
@media (max-width: 1024px) {
    .popup-layer {
        top: 100px !important;
        left: 12px !important;
    }
    .popup-layer__inner {
        width: calc(100vw - 24px) !important;
    }
    .popup-layer__body {
        width: 100%;
        height: auto !important;
    }
}
