
.main-section--professional {
    padding-top: var(--page-section-padding-y);
    max-width: 1920px;
    margin: 0 auto;
    // background-color: var(--v-grey-darken4);
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        z-index: -1;
        background-image: url(/images/main/professional-background-image.jpg);
        background-size: cover;
    }
    &__contents {
        &--right {
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                background-image: url(/images/main/professional-bg-mo.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section--professional {
        padding-top: 0;
        position: relative;
        background-color: transparent;
        &::after {
            max-width: calc(1338 / 1920 * 100%);
        }
        &__contents {
            height: 100%;
            padding-top: var(--page-section-padding-y);
            position: relative;
            &--left {
                padding-bottom: 120px;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100vw;
                    height: 100%;
                    // background-color: var(--v-grey-darken4);
                    top: 0;
                    right: calc(var(--grid-gutter) * -2);
                    z-index: -1;
                }
            }
            &--right {
                display: flex;
                align-items: flex-end;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: calc(50% + (var(--grid-gutter) * 1));
                    height: 100%;
                    // background-color: var(--v-grey-darken4);
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                &::before {
                    width: calc(50% - (var(--grid-gutter) * 1));
                    height: 100%;
                    left: unset;
                    right: 0;
                    z-index: -1;
                    background-image: url(/images/main/professional-bg.svg);
                    background-position: right center;
                }
            }
        }
    }
    .lawyer-info {
        overflow: hidden;
        max-height: 160px;
        flex-direction: column;
        > [class*="col"] {
            width: calc(100% / 3);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1640px) {
    .main-section--professional {
        &__contents {
            &--right {
                &::before {
                    display: none;
                }
            }
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            background-image: url(/images/main/professional-bg.svg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }
    }
}
