
.mobile-floating {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 10;
    &__inner {
        max-width: 1920px;
        margin: 0 auto;
    }
}
@media (min-width: 768px) {
    .mobile-floating {
        display: none;
    }
}
:deep() {
    .icon--size {
        &-small {
            width: 18px;
        }
    }
}
