
.v-tabs--dot {
    &.v-tabs {
        &,
        &--density-comfortable {
            height: calc(var(--tab-height-comfortable) + 4px);
            margin-bottom: -4px;
        }
        &--density-compact {
            height: calc(var(--tab-height-compact) + 4px);
            margin-bottom: -4px;
        }
        &--density-default {
            height: calc(var(--tab-height) + 4px);
            margin-bottom: -4px;
        }
        &--border,
        &--wide-border {
            &::before {
                bottom: 4px;
            }
        }
    }
}
