
.judgment-slide{
    --slide-margin: 14px;
    &-wrap{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 185.393%;
    }
    &.swiper-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(calc(var(--slide-margin) * -1));
        overflow: unset;
        ::v-deep{
            .swiper-wrapper{
                height: 100%;
            }
        }
    }
    .swiper-slide{
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        transition: .3s ease-out;
        &:not(.swiper-slide-active){
            padding-left: var(--slide-margin);
        }
        &.swiper-slide-prev{
            padding-left: 0;
            padding-right: var(--slide-margin);
            margin-left: var(--slide-margin);
        }
        &.swiper-slide-active{
            .story-item{
                box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .judgment-slide{
        --slide-margin: 34px;        
        &-wrap{
            padding-top: 191.379%;
        }
        .swiper-slide{
            &.swiper-slide-active{
                .story-item{
                    box-shadow: var(--shadow-elevation-16) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .judgment-slide{
        &-wrap{
            padding-top: 136.458%;
        }
    }
}
@media (min-width:1200px){
}

