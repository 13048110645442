
.tit{
    width: fit-content;
    position: relative;
    &__dot{
        position: absolute;
        display: block;
        width: 38px;
        height: 38px;
        top: 0;
        right: -26px;
        border-radius: 50%;
        // background-color: rgba(210, 178, 155, .4);
        background-color: rgba(255, 182, 193, .4);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit{
        &__dot{
            width: 56px;
            height: 56px;
            right: -32px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
