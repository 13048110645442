
.main-section{
    &--contact{
        background-image: url(/images/main/contact-bg.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}
.contact-item{
    &__inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        transition: .2s ease-out;
        &:not(.contact-item--disabled .contact-item__inner){
            background-color: rgba(0, 0, 0, .5);
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .contact-item:not(.contact-item--disabled):hover{
        .contact-item__inner{
            background-color: rgba(0, 0, 0, .8);
        }
    }
}
@media (min-width:1200px){
}

