
.gate-item{
    width: 100%;
    position: relative;
    transition: .2s ease-out;
    display: flex;
    align-items: center;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .3);
        opacity: 1;
        visibility: visible;
        transition: .2s ease-out;
        z-index: 0;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        border: 1px solid #fff;
        top: 24px;
        left: 24px;
        transform: scaleY(0);
        opacity: 0;
        visibility: hidden;
        transition: .5s ease-out;
        z-index: 1;
    }
    &__inner{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
.row >[class*="col"]:not(:first-child){
    .gate-item{
        &__inner{
            border-top: 1px solid rgba(255, 255, 255, .15);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row >[class*="col"]:not(:first-child){
        .gate-item{
            &__inner{
                border-top: 0;
                border-left: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
    .row >[class*="col"]:nth-child(1),
    .row >[class*="col"]:nth-child(2),
    .row >[class*="col"]:nth-child(3){
        .gate-item{
            &__inner{
                border-bottom: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
}
@media (min-width:1024px){
    .gate-item{
        min-height: 100vh;
        &::before{
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
        }
    }
    .gate-item:hover{
        background-color: rgba(0, 0, 0, .5) !important;
        &::before{
            opacity: 0;
            visibility: hidden;
        }
        &::after{
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: transform .5s .1s ease-out, opacity 1s, visibility 1s;
        }
    }
    .row >[class*="col"]:nth-child(1),
    .row >[class*="col"]:nth-child(2),
    .row >[class*="col"]:nth-child(3){
        .gate-item{
            &__inner{
                border-bottom: 0;
            }
        }
    }
}
@media (min-width:1200px){
}

