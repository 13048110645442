
.app--main .header:not(:hover, .scroll-active){
    .header-body--basic {
        background-color: transparent;
        ::v-deep{
            .logo a{
                background-image: url(/images/logo-light.svg);
            }
            .gnb__link:not(:hover, .gnb__item.active>.gnb__link){
                color: #fff !important;
            }
        }
    }
}
