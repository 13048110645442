
.solution-item {
    &__inner {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            transition: 0.2s ease-out;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .solution-item:hover {
        .solution-item__inner {
            // background-color: rgba(210, 178, 155, .6);
            &::before {
                background: var(--v-primary-base);
                opacity: 0.6;
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (min-width: 1200px) {
}
